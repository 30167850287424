<template>
    <div>
        <sav-template name="contact">
            <div class="mt-20 lg:mt-40 gap-10 grid lg:grid-cols-2 container mx-auto lg:px-4 md:px-0">
                <div>
                    <img class="hidden lg:block rounded-xl" src="https://images.unsplash.com/photo-1479920252409-6e3d8e8d4866?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80" alt="">
                </div>
                <div>
                    <div v-if="sended" class="bg-emerald-100 p-4 rounded-xl text-lg font-bold" id="message-sent">
                        <i class="fas fa-check mr-1"></i> Message envoyé
                    </div>
                    <div v-show="!sended" class="bg-emerald-100 p-10 rounded-xl w-full">
                        <h1 class="text-emerald-5OO text-3xl font-semibold">{{ $t('contact.form.title') }}</h1>

                        <input-rule v-model="prenom" class="mt-5" :check-rules="canCheckInputs" rules="required" type="text" id="prenom" :label="$t('contact.form.prenom')" />
                        <input-rule v-model="nom" class="mt-5" :check-rules="canCheckInputs" rules="required" type="text" id="nom" :label="$t('contact.form.nom')" />
                        <input-rule v-model="nom_entreprise" class="mt-5" :check-rules="canCheckInputs" rules="required" type="text" id="nom_entreprise" :label="$t('contact.form.nom_entreprise')" />
                        <input-rule v-model="email" class="mt-5" :check-rules="canCheckInputs" rules="required|email" type="text" id="email" :label="$t('contact.form.email')" />
                        <input-rule v-model="tel" class="mt-5" :check-rules="canCheckInputs" rules="required|tel" type="text" id="tel" :label="$t('contact.form.phone')" />
                        <input-rule v-model="message_content" class="mt-5" :label="$t('contact.form.message')" :check-rules="canCheckInputs" rules="required" type="textarea" id="message_content" />

                        <label class="mt-5 block mb-2 text-sm font-medium">{{ $t('contact.form.spam') }}</label>
                        <vue-recaptcha sitekey="6Lc32tMmAAAAAFBQ1fyUKl8FeuqI5GzxzmgRCWqF" @verify="validCaptcha"></vue-recaptcha>

                        <button :disabled="loadingSend" :class="loadingSend ? 'bg-emerald-200' : 'bg-emerald-600 hover:bg-emerald-600'" class="mt-5 cursor-pointer duration-200 p-2 px-4 text-white rounded-xl" @click="sendForm">
                            <template v-if="loadingSend">
                                <i class="fas fa-hourglass-half mr-1"></i> {{ $t('contact.form.envoie') }}
                            </template>
                            <template v-else>
                                <i class="fas fa-check mr-1"></i> {{ $t('contact.form.envoyer') }}
                            </template>
                        </button>

                        <div class="mt-5 font-light text-xs">
                            En renseignant les données personnelles demandez, vous consentez à ce que ces données soient collectées et traitées selon les dispositions de notre <a href="https://disticloudfiles.net/upload/files/Politique%20de%20confidentialite%20-%20SAVLOG.pdf" class="underline" target="_blank">Politique de confidentialité</a> et les réglementations en vigueur
                        </div>
                    </div>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    components: { VueRecaptcha },

    data() {
        return {
            nom: null,
            prenom: null,
            nom_entreprise: null,
            email: null,
            tel: null,
            message_content: null,

            canCheckInputs: false,
            captchaIsValid: false,

            loadingSend: false,

            sended: false,
        }
    },
    methods: {
        async sendForm() {
            this.canCheckInputs = true
            this.$nextTick(() => {
                this.canCheckInputs = false
            })

            let fields = ['nom', 'prenom', 'nom_entreprise', 'email', 'tel', 'message_content']
            let findError = false
            fields.forEach((field) => {
                if (!this[field] || !this[field].valid) {
                    findError = true
                    return
                }
            })
            if (findError) {
                this.$toast.error('Formulaire incomplet')
                return
            }

            var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!this.email.value.match(emailRegex)) {
                this.$toast.error('Format email invalide')
                return
            }

            var telRegex = new RegExp(/^(01|02|03|04|05|06|07)[0-9]{8}/gi);
            if (!telRegex.test(this.tel.value)) {
                this.$toast.error('Numéro de téléphone invalide')
                return
            }

            if (!this.captchaIsValid) {
                this.$toast.error('Vous devez confirmer la vérification de spam')
                return
            }
            this.loadingSend = true
            try {
                await apiService.post('email.contact', {
                    no_user: 1,
                    nom: this.nom.value,
                    prenom: this.prenom.value,
                    nom_entreprise: this.nom_entreprise.value,
                    email: this.email.value,
                    tel: this.tel.value,
                    message_content: this.message_content.value
                })

                // this.$toast.success('Message envoyé')
                this.sended = true
                if (!localStorage.getItem('sent')) {
                    // window.location.replace(window.location.href + '?sent=1')
                    // localStorage.setItem('sent', 1)
                } else {
                    // location.reload()
                }
            } catch (e) {
                this.$toast.error('Une erreur est survenue..')
            }
            this.loadingSend = false
        },
        
        validCaptcha() {
            this.captchaIsValid = true
        }
    },
    mounted() {
        // if (localStorage.getItem('sent')) {
        //     localStorage.removeItem('sent')
        //     this.$toast.success('Demande envoyée')
        // }
    }
}
</script>
