<template>
    <div>
        <!-- Header -->
        <div class="hidden md:block py-5 fixed border-b border-slate-200 top-0 w-full backdrop-blur-sm bg-white/90 bg-opacity-80" style="z-index: 999">
            <div class="md:flex items-center justify-between px-5 lg:px-0 lg:container mx-auto">
                <div class="flex items-center gap-14">
                    <div>
                        <h2 class="text-2xl font-semibold text-black">Districloud</h2>
                        <p class="text-xs font-light" v-html="$t('template.header.powered')"></p>
                    </div>
                    <ul class="flex items-center gap-8 font-light text-lg text-gray-400">
                        <!-- Home -->
                        <a href="/">
                            <li :class="isSelected(['home']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">{{ $t('template.header.accueil') }}</li>
                        </a>

                        <!-- Services -->
                        <li>
                            <dropdown-menu
                            v-model="displaySolutions"
                            :hover="true"
                            :hover_time="50"
                            :right="false"
                            >
                                <button id="services-btn dropdown-toggle" :class="isSelected(['selfcare', 'shop', 'supplier', 'repairer', 'insurer']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">
                                    {{ $t('template.header.solutions') }} <i class="fas fa-chevron-down ml-1 text-sm"></i>
                                </button>
                                <div slot="dropdown" class="fixed bg-white rounded-xl mt-5 shadow p-8 text-slate-800">
                                    <div class="font-bold uppercase mb-2 text-sm" v-html="$t('template.header.who')"></div>
                                    <ul class="mt-3 ml-4">
                                        <li
                                        class="cursor-pointer mb-2 bullet-emerald"
                                        v-for="(item, itemIndex) in subMenuItems"
                                        :key="'menu-item-' + itemIndex"
                                        >
                                            <a :href="item.url" v-html="item.label" :target="item.target"></a>
                                        </li>
                                    </ul>
                                </div>
                            </dropdown-menu>
                        </li>

                        <!-- pricing -->
                        <!-- <a href="/tarifs">
                            <li :class="isSelected(['pricing']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">{{ $t('template.header.pricing') }}</li>
                        </a> -->

                        <!-- about -->
                        <a href="/a-propos">
                            <li :class="isSelected(['about']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">{{ $t('template.header.about') }}</li>
                        </a>
                    </ul>
                </div>

                <div class="flex items-center gap-3">                    
                    <!-- Connect -->
                    <a href="http://districloud.net" class="bg-slate-200 hover:bg-slate-300 px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.connect')"></a>
                    <a href="http://distriadmin.savlog.net/inscription" class="bg-slate-200 hover:bg-slate-300 px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.register')"></a>

                    <!-- Contact button -->
                    <a href="/contact" class="hidden lg:block bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.ask_demo')"></a>
                    <a href="/contact" class="lg:hidden bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.demo')"></a>


                    <!-- Translate button -->
                    <div class="flex items-center gap-2">
                        <i class="fa-solid fa-globe"></i>
                        <dropdown-menu
                        v-model="displayLanguages"
                        :hover="true"
                        :hover_time="50"
                        :right="false"
                        >
                            <button id="services-btn dropdown-toggle" class="hover:text-emerald-600 duration-200 cursor-pointer'">
                                {{ $t('template.header.langue') }} <i class="text-xs fas fa-chevron-down ml-1"></i>
                            </button>
                            <div slot="dropdown" class="bg-white py-1 px-2 fixed rounded-xl text-slate-800 -ml-5">
                                <ul>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('fr')">
                                        <img class="h-3 w-5" src="/img/fr_flag.png" alt="fr flag"> FR
                                    </li>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('en')">
                                        <img class="h-3 w-5" src="/img/en_flag.png" alt="en flag"> EN
                                    </li>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('es')">
                                        <img class="h-3 w-5" src="/img/es_flag.png" alt="es flag"> ES
                                    </li>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('it')">
                                        <img class="h-3 w-5" src="/img/it_flag.png" alt="it flag"> IT
                                    </li>
                                </ul>
                            </div>
                        </dropdown-menu>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile button -->
        <div class="bg-white fixed top-0 left-0 right-0 p-2 px-5 bg-opacity-80 bg-blur flex justify-end">
            <button @click="displayMobileMenu = true">
                <i class="fas fa-bars text-3xl text-gray-500"></i>
            </button>
        </div>
        <!-- Mobile menu -->
        <div v-if="displayMobileMenu">
            <div @click="displayMobileMenu = false" class="z-20 bg-black bg-opacity-70 fixed top-0 left-0 right-0 bottom-0"></div>
            <div data-aos="slide-right" data-aos-delay="100" class="bg-white fixed top-0 bottom-0 left-0 right-20 p-10 z-20 overflow-y-auto">
                <div class="flex items-center justify-between">
                    <div>
                        <h2 class="text-xl font-bold">Districloud</h2>
                        <p class="text-xs font-light" v-html="$t('template.header.powered')"></p>
                        <h2 class="font-base text-lg text-emerald-600">{{ pageName }}</h2>
                    </div>
                    <button @click="displayMobileMenu = false" class="bg-red-500 text-white rounded-xl p-2 px-4"><i class="fas fa-times"></i></button>
                </div>
                
                <div class="my-8">
                    <a href="/">
                        <div class="text-2xl">{{ $t('template.header.accueil') }}</div>
                    </a>
                </div>

                <div class="my-8">
                    <h3 class="text-2xl">{{ $t('template.header.solutions') }}</h3>
                    <ul class="pl-5 mt-3">
                        <a :href="item.url" v-for="(item, itemIndex) in subMenuItems" :key="'menu-item-' + itemIndex">
                            <li
                            class="cursor-pointer mb-2 bullet-emerald"
                            v-html="item.label"
                            >
                            </li>
                        </a>
                    </ul>
                </div>
                <!-- <div class="my-8">
                    <a href="/tarifs">
                        <div class="text-2xl">{{ $t('template.header.pricing') }}</div>
                    </a>
                </div> -->
                <div class="my-8">
                    <a href="/a-propos">
                        <div class="text-2xl">{{ $t('template.header.about') }}</div>
                    </a>
                </div>
                <div class="my-8">
                    <a href="/contact">
                        <div class="text-2xl">{{ $t('template.header.contact') }}</div>
                    </a>
                </div>
                <div class="my-8">
                    <a href="http://distriadmin.savlog.net/inscription">
                        <div class="text-2xl">{{ $t('template.header.register') }}</div>
                    </a>
                </div>
                <div class="my-8">
                    <dropdown-menu
                    v-model="displayLanguages"
                    :hover="true"
                    :hover_time="50"
                    :right="false"
                    >
                        <button id="services-btn dropdown-toggle" class="text-sm hover:text-emerald-600 duration-200 cursor-pointer'">
                            {{ $t('template.header.langue') }} <i class="text-xs fas fa-chevron-down ml-1"></i>
                        </button>
                        <div slot="dropdown" class="bg-white fixed rounded-xl text-slate-800">
                            <ul>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('fr')">FR</li>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('en')">EN</li>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('es')">ES</li>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('it')">IT</li>
                            </ul>
                        </div>
                    </dropdown-menu>
                </div>
            </div>
        </div>
        

        <!-- Website content -->
        <div class="min-h-screen">
            <slot></slot>
        </div>

        <!-- Button scroll top -->
        <button @click="scrollToTop" :class="classBtnScrollTop" class="bg-emerald-600 hover:bg-emerald-700 duration-200 cursor-pointer p-2 px-3 rounded-full text-white fixed bottom-8 right-8">
            <i class="fas fa-chevron-up"></i>
        </button>

        <!-- Footer -->
        <div class="pt-20 pb-10">
            <div class="container mx-auto px-4 lg:px-0">
                <div class="lg:flex items-center justify-between gap-20">
                    <div class="text-center lg:text-left lg:w-1/3 xl:w-2/4">
                        <span class="text-5xl font-black">Districloud</span>
                        <p class="text-xl font-extralight mt-2 lg:pr-20 mb-3">{{ $t('template.footer.subtitle') }}</p>
                    </div>
                    <div v-if="$route.name != 'contact'" @click="$router.push({name: 'contact'})" class="text-center bg-emerald-700 hover:bg-emerald-600 cursor-pointer duration-200 hover:translate-y-1 flex items-center justify-center p-5 py-8 rounded-xl text-white">
                        <i class="fas fa-envelope text-5xl"></i>
                        <div class="ml-5 text-left">
                            <div class="text-xl font-bold">{{ $t('template.footer.email') }}</div>
                        </div>
                    </div>
                </div>
    
                <div class="mt-20 font-extralight text-xs grid grid-cols-3">
                    <div>
                        <div>TVA Intracommunautaire: FR66799421250</div>
                        <div>Numéro RCS: Brive B 799 421 250</div>
                        <div>RGPD: rgpd@savlog.net</div>
                    </div>
                    <div class="text-center">
                        <div>© 1998 - 2025 <a class="underline" target="_blank" href="http://www.savlog.net">SAVLOG SAS</a> {{ $t('template.footer.capital') }} | All Rights Reserved</div>
                    </div>
                    <div class="text-right">
                        <div><a href="https://disticloudfiles.net/upload/files/Politique%20de%20confidentialite%20-%20SAVLOG.pdf" target="_blank" class="underline">Accéder à la politique de confidentialité</a></div>
                        <div>Connexion au logiciel: <a class="underline" target="_blank" href="https://www.districloud.net">https://districloud.net</a></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Cookies -->
        <modal name="sav-setting-cookie" height="auto" :scrollable="true">
            <div class="p-4">
                <div class="mb-4 pb-4 border-b flex items-center justify-between text-2xl">
                    <h1 class="font-bold">Configurer les cookies</h1>
                    <button @click="$modal.hide('sav-setting-cookie')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="font-bold text-xl">Cookies strictement nécessaires :</div>
                <div>Ces cookies sont strictement nécessaires au fonctionnement du site Web et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement établis en tant que réponse à des actions que vous avez effectuées et qui constituent une demande de services comme : la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires, etc Vous pouvez configurer votre navigateur afin de bloquer certains cookies comme détaillé dans la partie « Comment gérer mes cookies ?» de notre <a href="https://disticloudfiles.net/upload/files/Politique%20de%20cookies%20-%20SAVLOG.pdf" target="_blank" class="font-bold underline">Politique des cookies</a>.</div>
                <div class="border-t mt-4"></div>
                <div class="mt-4 flex items-start">
                    <input v-model="acceptCookieFonctionnalite" type="checkbox" class="focus:outline-none mt-1.5 mr-5" />
                    <div>
                        <b>Cookies de performance</b> : ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic afin de mesure et d’améliorer les performances de notre site Web. Si vous n’acceptez pas ces cookies, nous ne serons pas informés de votre visite sur notre site.
                    </div>
                </div>
                <div class="mt-4 flex items-start">
                    <button @click="$toast.warning('Ces cookies sont obligatoires')" class="mt-1.5 mr-5" style="font-size: 16px"><i class="fa-solid fa-square-check text-orange-600"></i></button>
                    <div>
                        <b>Cookies de fonctionnalité</b> : ces cookies permettent d’améliorer et de personnaliser les fonctionnalités des site web. Si vous n’acceptez pas ces cookies, une partie ou la totalité des services risquent de ne pas fonctionner correctement ou de vous être inaccessibles.
                    </div>
                </div>
                <div class="border-t mt-4"></div>
                <div class="mt-4 text-xs font-light">En renseignant les données personnelles demandez, vous consentez à ce que ces données soient collectées et traitées selon les dispositions de notre <a href="https://disticloudfiles.net/upload/files/Politique%20de%20confidentialite%20-%20SAVLOG.pdf" class="underline" target="_blank">Politique de confidentialité</a> et les réglementations en vigueur</div>
                <div class="border-t mt-4"></div>
                <button @click="setRgpdCookie({perf: 0, fonctionnalite: acceptCookieFonctionnalite ? 1 : 0})" class="px-4 py-2 rounded mt-4 bg-emerald-800 text-white"><i class="fas fa-check mr-2"></i> Valider</button>
            </div>
        </modal>
        <div v-if="displayCookie" class="fixed bottom-5 left-5 p-4 rounded bg-slate-50 shadow-lg shadow-slate-400 border w-[500px]">
            <div class="flex items-center gap-2">
                <img class="h-[30px]" src="https://cdn-icons-png.flaticon.com/512/1047/1047711.png" alt="">
                <div class="text-lg font-bold">Politique des cookies</div>
            </div>
            <div class="text-xs mt-2">
                Avec votre consentement nous utilisons des cookies internes et tiers pour faciliter votre expérience avec nos services et analyser notre trafic. Nous partageons également des informations, quant à votre navigation sur notre site, avec nos partenaires analytiques et de réseaux sociaux. Vous pouvez gérer ou retirer votre consentement à tout moment. Nous conservons votre choix pendant la période légale maximum c’est à dire 13 mois. Vous pouvez paramétrer les cookies en cliquant sur le bouton ci-dessous. Pour plus d’informations sur l’utilisation des cookies, vous pouvez consulter notre <a href="https://disticloudfiles.net/upload/files/Politique%20de%20cookies%20-%20SAVLOG.pdf" target="_blank" class="font-bold underline">Politique des cookies</a>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-2">
                <button @click="setRgpdCookie({perf: 0, fonctionnalite: 1})" class="px-2 py-1.5 rounded bg-slate-300">Je refuse</button>
                <button @click="setRgpdCookie({perf: 1, fonctionnalite: 1})" class="px-2 py-1.5 rounded bg-emerald-800 text-white">J'accepte</button>
            </div>
            <div @click="$modal.show('sav-setting-cookie')" class="text-xs font-light mt-2 cursor-pointer hover:underline"><i class="fas fa-cog mr-1"></i> Paramétrer les cookies</div>
        </div>
    </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu'
export default {
    components: {DropdownMenu},

    props: {
        pageName: {}
    },

    watch: {
        displaySolutions(value) {
            if (!value) {
                this.selectedMenu = 'districloud'
            }
        }
    },

    data() {
        return {
            displayMobileMenu: false,
            displaySolutions: false,
            displayLanguages: false,

            selectedMenu: 'districloud',

            classBtnScrollTop: 'hidden',

            // RGPD Cookie
            displayCookie: false,
            acceptCookieFonctionnalite: true,
        }
    },

    computed: {
        subMenuItems() {
            return [
                // "districloud": {
                //     title: 'Districloud',
                //     items: [
                //         {label: "Fournisseur", route: 'services.supplier'},
                //         {label: "Distributeur", route: 'services.shop'},
                //         {label: "Réparateur", route: null},
                //     ],
                // },
                // "savlogsta": {
                //     title: 'Savlog STA',
                //     items: [
                //         {label: "Centre de réparation", route: 'services.repairStation'},
                //         {label: "Api", route: null},
                //         {label: "Auto-entrepreneur", route: null},
                //     ],
                // },
                // "selfcare": {
                //     title: 'Selfcare',
                //     items: [
                //         {label: "Distributeur", route: 'services.selfcare'},
                //         {label: "Fournisseur", route: 'services.selfcare'},
                //         {label: "Particulier", route: 'services.selfcare'},
                //     ],
                // },
                // "mobilite": {
                //     title: 'Mobilité',
                //     items: [
                //         {label: "Centre technique", route: null},
                //         {label: "Auto-entrepreneur", route: null},
                //     ],
                // },
                // "interconnect": {
                //     title: 'Interconnexion',
                //     items: [
                //         {label: "En savoir plus", route: null},
                //         {label: "Documentation développeur", route: null},
                //     ],
                // },
                // "all": {
                //     title: 'Toutes les solutions',
                //     items: [
                //         {label: "Toutes nos solutions", route: 'services.solutions'},
                //     ]
                // }
                {
                    "url": "/fournisseur",
                    "label": this.$t('supplier.titre')
                },
                {
                    "url": "/distributeur",
                    "label": this.$t('shop.titre')
                },
                {
                    "url": "/reparateur",
                    "label": this.$t('repairer.titre')
                },
                {
                    "url": "/assureur",
                    "label": this.$t('insurer.titre')
                },
                {
                    "url": "https://www.districloud.net/dev/cases_api.html",
                    "label": this.$t('developer.titre'),
                    "target": "_blank"
                },
            ]
        }
    },

    methods: {
        isSelected(names) {
            return names.includes(this.$route.name)
        },

        translateTo(lang) {
            localStorage.setItem('lang', lang)
            this.$i18n.locale = lang
        },

        scrollToTop() {
            window.scrollTo({top: 0, behavior: 'smooth'})
        },

        setRgpdCookie(data) {
            let now = new Date();
            let expiryDate = new Date();
            expiryDate.setMonth(now.getMonth() + 13);
            data.expiry = expiryDate.getTime()
            localStorage.setItem('accept-cookie', JSON.stringify(data))
            this.displayCookie = false

            if (data.perf == 1) {
                this.loadScripts()
            }

            this.$modal.hide('sav-setting-cookie')
        },

        loadScripts() {            
            // LogRocket
            const logRocketScript = document.createElement('script');
            logRocketScript.src = 'https://cdn.ingest-lr.com/LogRocket.min.js';
            logRocketScript.crossOrigin = 'anonymous';
            logRocketScript.onload = () => {
                if (window.LogRocket) {
                    window.LogRocket.init('omg4ub/savlog');
                }
            };
            document.head.appendChild(logRocketScript);

            // Google Analytics (gtag.js)
            const gtagScript = document.createElement('script');
            gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-F9PH0N5GJD';
            gtagScript.async = true;
            gtagScript.onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() { window.dataLayer.push(arguments); }
                gtag('js', new Date());
                gtag('config', 'G-F9PH0N5GJD');
            };
            document.head.appendChild(gtagScript);

            // Google Tag Manager
            const gtmScript = document.createElement('script');
            gtmScript.textContent = `
                (function(w,d,s,l,i){
                    w[l]=w[l]||[];
                    w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                    f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-KBLGFLD5');
            `;
            document.head.appendChild(gtmScript);
        }
    },

    mounted () {
        if (localStorage.getItem('lang')) {
            this.$i18n.locale = localStorage.getItem('lang')
        }

        window.addEventListener("scroll", () => {
            if (this.classBtnScrollTop != 'hidden' && window.scrollY < 1000) {
                this.classBtnScrollTop = 'hidden'
            }
            if (this.classBtnScrollTop != '' && window.scrollY >= 1000) {
                this.classBtnScrollTop = ''
            }
        })

        if (localStorage.getItem('accept-cookie') == null) {
            this.displayCookie = true
        } else {
            let rgpd = JSON.parse(localStorage.getItem('accept-cookie'))
            let now = new Date();
            if (now.getTime() > rgpd.expiry) {
                localStorage.removeItem('accept-cookie');
                this.displayCookie = true
            } else {
    
                if (rgpd.perf) {
                    this.loadScripts()
                }
            }
        }
    },
}
</script>